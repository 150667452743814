import { AppBar, Container, Grid } from "@mui/material";
import style from "./style.module.css";

export const Top = () => (
  <AppBar position="static">
    <Container>
      <Grid container>
        <Grid item xs={4}>
          <img
            src="https://imagedelivery.net/cLx_YugOmMyoIRjB2imkyg/16aa6f1c-e31f-4b84-6e85-c131da42bf00/public"
            style={{ maxWidth: 150, height: "auto" }}
          />
        </Grid>
        <Grid item xs={8}>
          <section className={style.sloganSection}>
            <span className={style.slogan}> Hot Deals Every Day!</span>
          </section>
        </Grid>
      </Grid>
    </Container>
  </AppBar>
);
