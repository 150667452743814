import React from "react";
import "./App.css";
import * as Component from "./components";
import { Grid, Container } from "@mui/material";

function App() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Component.Top />
      </Grid>
      <Grid item xs={12}>
        <Container style={{ marginTop: 50 }}>
          <p className="grand">We Are Open!</p>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
          <a href="https://goo.gl/maps/qmDdH5gewH83k1c17">
            <img
              src="https://imagedelivery.net/cLx_YugOmMyoIRjB2imkyg/6e4d1767-a50e-4806-5198-dc4300852500/public"
              alt="Promo"
              style={{ height: "65vh", borderRadius: "3%" }}
            />
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

export default App;
